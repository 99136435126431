$theme-color: #2E4C6D;
$divider-color: #E5E7EB;
$button-color: #2E4C6D;
$button-red-color: #E12237;
$backgroun-primary: #2E4C6D;
$backgroun-primary-light: #2E4C6D;
$button-active-color: #0b845b;
$sidebar-color: #331f20;
$sidebar-background-color: #2E4C6D;
$sidebar-active-background-color: #2B425B;
$wrapper-theme-div-padding: 23px 20px;
$dropdown-theme-green-border-color: #e69e84;
$default-black-color: #111928;
$default-gray-color: #4B5563;
$white-color: #ffffff;
$icon-black-color: #000000;
$dropdown-color: #2E4C6D;
// $text-primary: #216EC3; // Global css start
$text-primary: $theme-color;
* {
  font-family: 'Inter', sans-serif
}

.align-top {
  vertical-align: top;
}

// Navbar dropdown
.navbar-custom-dropdown {
  img {
    padding-right: 8px;
  }

  i {
    padding-left: 26px;
    padding-right: 12px;
  }
}

.theme-top-nav {
  padding: 5px 30px;
}

.border-transparent {
  border: transparent;
}

.nav-bg-color {
  background-color: $sidebar-background-color;
}

.bg-emerald-sidebar-custom {
  background: $sidebar-active-background-color;
}

.weight-400 {
  font-weight: 400;
}

.line-height24 {
  line-height: 24px;
}

.letter-spacing {
  letter-spacing: -0.333333px;
}

.line-height22 {
  line-height: 22px;
}

.border-top-black {
  border-top: 1px solid #000000 !important;
}

.text-gray {
  color: #4B5563;
}

.text-dark-gray {
  color: #3f3f3f;
}

.header-align {
  text-align: left;
  padding-left: 35px;
}

.background-color-dark-gray {
  background-color: #C4C4C4;
}

.background-gray {
  background-color: #F3F4F6;
}

.table-backgroud {
  background-color: #E4E4E4;;
}

.disable-input-background{
  background: #F5F2D1;
}

.bg-white-dark{
  background-color: #d3d2d2;
}

.text-green {
  color: #4EB780;
}

.text-primary {
  color: $text-primary;
}

.text-red {
  color: rgb(240, 11, 11);
}

.text-black {
  color: black;
}

.text-light-yellow {
  color: #e9e5c6
}

.font-20 {
  font-size: 20px;
}

.nav-image {
  width: 'auto';
  height: 33px;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

.font-24 {
  font-size: 24px;
}

.width-250 {
  width: 250px;
}

.font-46 {
  font-size: 46px;
}

.border-radius-5 {
  border-radius: 5px;
}

input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 3;
  padding: 0px !important;
}

.image-center {
  position: relative;
  left: 45px;
}

.chip {
  word-break: break-all !important;
  white-space: break-spaces !important;
}

input:focus {
  outline-color: none !important;
  border-color: none !important
}

.shop-image-layout {
  width: 100px;
  height: 100px;
}

.w-min-content {
  width: min-content;
}

.multiSelectContainer {
  width: 100% !important;
}

.w-webkit-fill-available {
  width: -webkit-fill-available;
}

.w-450 {
  width: 450px;
}

.highlightOption {
  background-color: #ffffff !important;
  color: #0E9F6E !important
}

.checkbox {
  color: #0E9F6E;
  border: 1px solid !important;
  border-color: #0E9F6E !important;
}

.chip {
  background-color: #0E9F6E !important;
}


.w-518 {
  width: 518px;
}

// Table design

.table-borderd {
  tbody:before {
    line-height: 6px;
    content: "\200C";
    display: block;
  }

  tbody tr {
    border-bottom: 2px solid #E5E7EB;
  }

  tr:nth-child(odd) {
    background-color: #F3F4F6;
  }

  .table-heading {
    background-color: #F3F4F6 !important;
  }

  .table-count {
    background-color: white !important;
  }
}

.transaction-list {
  color: black;
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
  padding: 5px 0;

  li {
    padding: 10px 0;
    padding-left: 25px;
    background: white;
  }

  ul li:nth-child(even) {
    background-color: #F3F4F6;
  }
}

.cursor-default {
  cursor: default;
}

// User menu dropdown

.user-menu-dropdown {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin: 10px 0 0 0 !important;

  a {
    padding: 12px 126px 12px 16px;
  }
}

.link {
  color: rgb(37 99 235);
  text-decoration: underline;
}
.hover\:link:hover {
  color: #0000EE;
  text-decoration: none;
}
.visited\:link:visited {
  color:#551A8B;
}

.w-50 {
  width: 50%;
}

.box-shadow-black {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
}

.border-gray {
  border: solid 1px #dfdfdf;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.h-115-px {
  height: 115px;
}

.background-red {
  background-color: #d1391d;
}

.bg-green-400 {
  background-color: #51b780;
}

.bg-green-500 {
  background-color: #36ad6c;
}

.border-green {
  border-color: #4EB780;
}

.border-blue {
  border-color: #4A90E2;
}

.border-yellow {
  border-color: #F5A623;
}
.border-primary {
  border-color: $text-primary
}

.background-purple {
  background-color: #936dc7;
}

.background-yellow {
  background-color: #f5a72f;
}

.background-blue {
  background-color: #4a90e2;
}

.search-icon {
  left: -2px;
}

.multiSelectContainer {
  .search-wrapper {
    padding: 0;
  }

  .chip {
    margin: 0.5rem 0 0.75rem 0.75rem;
  }

  input.searchBox {
    width: 100%;
    border-color: rgb(209 213 219/1);
    font-size: 16px;
    padding: .5rem .75rem !important;
    margin-top: 0 !important;
  }

  input.searchBox:focus {
    --tw-ring-shadow: none
  }

  .option .checkbox {
    vertical-align: top;
  }
}

.header-logo {
  width: 180px;
  // padding-top: 5px;
}

.btn-theme-green {
  background-color: $button-color;
}

.btn-primary {
  background-color: $button-color;
}

.theme-border{
  border-color: $theme-color;
}

.btn-red {
  background-color: $button-red-color;
}

.btn-gray {
  background-color: $default-gray-color;
}

.btn-primary-light {
  background-color: $backgroun-primary-light;
}

.home-container {
  margin: auto;
  max-width: 1100px;
}

.home-card {
  min-width: 340px;
  margin-bottom: 20px;
}

@media (min-width: 640px) {
  .home-card {
    min-width: 400px !important;
  }
}

@media (min-width: 1024px) {
  .home-card {
    min-width: 500px !important;
  }
}

/* toogle css */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 25px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.focus\:radio-checked:focus {
  --tw-ring-opacity: 0.5 !important;
  --tw-ring-color: #ff632a !important;
}

.disabled\:btn-disabled:disabled {
  background-color: $default-gray-color;
}


.slider:before {
  position: absolute;
  content: "";
  height: 19px;
  width: 19px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: $backgroun-primary;
}

input:focus+.slider {
  box-shadow: 0 0 1px $backgroun-primary;
}

input:checked+.slider:before {
  -webkit-transform: translateX(33px);
  -ms-transform: translateX(33px);
  transform: translateX(33px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

input[type='checkbox']:focus,
input[type='radio']:focus,
input[type='radio'],
input[type='checkbox']{
  color: $text-primary;
  box-shadow: none;
}
.popover {
  height:6rem;
  width:10rem;
  position:absolute;
  right: 2.5rem;
  top:1rem;
  background-color:#ffffff;
  border-radius: 0.25rem;
  display: none;
  z-index: 1;
}

.animate-sidebar {
  transition: margin 0.5s ease
}

.hover\:pagination:hover {
  background-color: $backgroun-primary;
  color: #ffffff;
} 